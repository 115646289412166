import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-92fb943c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon");
  const _component_Eldialog = _resolveComponent("Eldialog");
  return _openBlock(), _createBlock(_component_Eldialog, _mergeProps(_ctx.$attrs, {
    visible: _ctx.show,
    "onUpdate:visible": _cache[0] || (_cache[0] = $event => _ctx.show = $event),
    title: _ctx.title,
    width: "360px",
    onConfirmOk: _ctx.confirm
  }), {
    form: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_Icon, {
      name: "Warning",
      class: "content_icon"
    }), _createTextVNode(" 是否确认解绑设备？ ")])]),
    _: 1
  }, 16, ["visible", "title", "onConfirmOk"]);
}