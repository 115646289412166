import { defineComponent, toRefs, reactive, computed, defineAsyncComponent } from 'vue';
import Eldialog from '@/components/Eldialog/index.vue';
import { ElSwitch } from 'element-plus';
export default defineComponent({
  components: {
    Eldialog,
    ElSwitch,
    TreeSelect: defineAsyncComponent(() => import('@/components/TreeSelect/TreeSelect.vue'))
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {
    emit
  }) {
    const refData = reactive({
      title: '提示'
    });
    // 初始化表单
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit('update:visible', val);
      }
    });
    // 结构树回调
    const handleClick = data => {
      console.log(data);
    };
    // 确定按钮
    const confirm = () => {
      emit('update:visible', false);
    };
    return {
      ...toRefs(refData),
      show,
      handleClick,
      confirm
    };
  }
});